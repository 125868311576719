import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './QueryStyles';
import { usePoolsQuery } from '../context/PoolsQueryContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://loftyquery.app';

const tableStyles = {
  ...styles,
  table: {
    ...styles.table,
    tableLayout: 'fixed',
    width: '100%',
  },
  th: {
    ...styles.th,
    width: '50%',  // Set both columns to equal width
  },
  td: {
    ...styles.td,
    width: '50%',  // Set both columns to equal width
    wordBreak: 'break-word',  // Allow long words to break and wrap
  },
  backButton: {
    ...styles.button,
    marginBottom: '20px',
  }
};

const PoolDetail = () => {
    const { assetUnit } = useParams();
    const navigate = useNavigate();
    const { poolsQueryState } = usePoolsQuery();
    const [poolData, setPoolData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPoolData = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/pool/${assetUnit}`);
        setPoolData(response.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching pool data:', err);
        setError('Failed to fetch pool data. Please try again.');
        setPoolData(null);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPoolData();
  }, [assetUnit]);

  const handleBack = () => {
    if (poolsQueryState?.queryResult) {
      navigate('/pools-query-builder');
    } else {
      navigate('/');
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div style={styles.error}>{error}</div>;
  if (!poolData) return <div>No data available</div>;

  return (
    <div style={styles.container}>
      <button 
        onClick={handleBack} 
        style={tableStyles.backButton}
      >
        ← Back {poolsQueryState?.queryResult ? 'to Query Results' : ''}
      </button>
      
      <h2 style={styles.header}>Pool Details: {assetUnit}</h2>
      
      <table style={tableStyles.table}>
        <thead>
          <tr>
            <th style={tableStyles.th}>Attribute</th>
            <th style={tableStyles.th}>Value</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(poolData).map(([key, value]) => (
            <tr key={key}>
              <td style={tableStyles.td}>{key}</td>
              <td style={tableStyles.td}>
                {typeof value === 'boolean'
                  ? (value ? '✓' : '✗')
                  : value?.toString()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PoolDetail;
