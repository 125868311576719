// File: src/App.jsx

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import TablePage from './pages/TablePage';
import Query from './components/Query';
//import PoolsQueryBuilder from './components/PoolsQueryBuilder';
//import QueryBuilder from './components/QueryBuilder';
import PoolDetail from './components/PoolDetail';
import { PoolsQueryProvider } from './context/PoolsQueryContext';

const appStyles = {
  container: {
    display: 'flex',
  },
  content: (isCollapsed) => ({
    marginLeft: isCollapsed ? '70px' : '230px', // Adjusted for sidebar width + gap
    flexGrow: 1,
    padding: '20px',
    transition: 'margin-left 0.3s ease',
  }),
};

const App = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  return (
    <Router>
      <PoolsQueryProvider>
        <div style={appStyles.container}>
          <Sidebar 
            isCollapsed={isSidebarCollapsed} 
            onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)} 
          />
          <div style={appStyles.content(isSidebarCollapsed)}>
            <Routes>
              <Route path="/" element={<Navigate replace to="/query" />} />
              <Route path="/table/:id" element={<TablePage />} />
              <Route path="/query" element={<Query />} />
              <Route path="/pool/:assetUnit" element={<PoolDetail />} />
              <Route path="/query-result" element={<TablePage />} />
            </Routes>
          </div>
        </div>
      </PoolsQueryProvider>
    </Router>
  );
};

export default App;
