// File: src/components/QueryStyles.js

const styles = {
    pageContainer: {
      display: 'flex',
      height: '100vh',
      overflow: 'hidden',
    },
    mainContent: {
      flexGrow: 1,
      padding: '20px',
      overflowY: 'auto',
      transition: 'margin-left 0.3s ease',
    },
    queryBuilder: {
      // fontFamily: 'Poppins, sans-serif',
      fontFamily: 'Arial, sans-serif',
      maxWidth: '1200px',
      margin: '0 auto',
    },
    form: {
      marginBottom: '20px',
    },
    inputGroup: {
      marginBottom: '15px',  // Add space between input groups
      maxWidth: '1170px', // Adjust this value as needed
    },
    label: {
      display: 'block',  // Make label a block element
      marginBottom: '5px',  // Add space below the label
      fontWeight: 'bold',  // Optional: make labels bold
    },
    input: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '14px',  // Optional: adjust font size
    },
    button: {
      padding: '10px 15px',
      backgroundColor: '#6e62f0',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '16px',  // Optional: adjust font size
    },
    table: {
      width: '100%',
      borderCollapse: 'collapse',
      marginTop: '20px',  // Add space above the table
    },
    th: {
      backgroundColor: '#f2f2f2',
      border: '1px solid #ddd',
      padding: '12px',
      textAlign: 'left',
    },
    td: {
      border: '1px solid #ddd',
      padding: '12px',
    },
    link: {
      color: '#1a73e8',
      textDecoration: 'none',
    },
    error: {
      color: 'red',
      marginBottom: '10px',
    },
    suggestionList: {
      listStyle: 'none',
      padding: 0,
      margin: '5px 0 0',  // Add space above suggestion list
      border: '1px solid #ddd',
      maxHeight: '150px',
      overflowY: 'auto',
    },
    suggestionItem: {
      padding: '8px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    },
  };
  
  export default styles;
