import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const sidebarStyles = {
  sidebar: (isCollapsed) => ({
    width: isCollapsed ? '60px' : '220px',
    height: '100vh',
    backgroundColor: '#4e3fec',
    color: 'white',
    padding: '20px 0',
    position: 'fixed',
    left: 0,
    top: 0,
    transition: 'width 0.3s ease',
    overflow: 'hidden',
    boxSizing: 'border-box',
  }),
  innerSidebar: (isCollapsed) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }),
  logo: {
    padding: '10px 20px',
    marginBottom: '0',
  },
  logoText: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'white',
    textDecoration: 'none',
    display: 'block',
  },
  description: {
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.8)',
    lineHeight: '1.5',
    marginBottom: '40px',
    whiteSpace: 'normal',
    maxWidth: '180px',
  },
  nav: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  navItem: {
    margin: 0,
    whiteSpace: 'nowrap',
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    display: 'block',
    padding: '10px 20px',
    transition: 'background-color 0.3s',
  },
  navLinkContent: {
    padding: '0',
  },
  navLinkTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  navLinkDescription: {
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.8)',
    lineHeight: '1.5',
    marginTop: '5px',
    whiteSpace: 'normal',
    maxWidth: '180px',
  },
  activeNavLink: {
    backgroundColor: '#3a47d5',
  },
  collapseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    color: 'white',
    fontSize: '20px',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
  },
  infoContainer: {
    marginTop: 'auto',
    padding: '20px',
  },
  email: {
    fontSize: '12px',
    marginBottom: '10px',
    color: 'rgba(255, 255, 255, 0.8)',
    lineHeight: '1.5',
  },
  disclaimer: {
    fontSize: '12px',
    color: 'rgba(255, 255, 255, 0.8)',
    lineHeight: '1.5',
  },
};

const Sidebar = ({ isCollapsed, onToggle }) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const username = 'lucas';
    const domain = 'loftyquery.app';
    setEmail(`${username}@${domain}`);
  }, []);

  return (
    <div style={sidebarStyles.sidebar(isCollapsed)}>
      <div style={sidebarStyles.innerSidebar(isCollapsed)}>
        <button
          style={{
            ...sidebarStyles.collapseButton,
            transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
          onClick={onToggle}
        >
          &#9654;
        </button>
        <div style={sidebarStyles.logo}>
          {!isCollapsed && (
            <>
              <a href="https://loftyquery.app" style={sidebarStyles.logoText}>
                Lofty Query
              </a>
              <div style={sidebarStyles.description}>
                Search for and filter properties from{' '}
                <a
                  href="https://lofty.ai"
                  style={{
                    color: 'white',
                    textDecoration: 'underline'
                  }}
                >
                  Lofty
                </a>
              </div>
            </>
          )}
        </div>
        {!isCollapsed && (
          <div style={sidebarStyles.infoContainer}>
            <div style={sidebarStyles.email}>
              Questions, bugs, or suggestions?<br />
              {email}
            </div>
            <div style={sidebarStyles.disclaimer}>
              This tool is unaffiliated with Lofty. Data is updated once per day from Lofty. No warranty is made so please double-check results.
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
