import React, { useState } from 'react';
import { HelpCircle } from 'lucide-react';

const PropertyFilterHelpButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();  // Prevent form submission
    e.stopPropagation(); // Stop event bubbling
    setIsOpen(true);
  };

  const iconButtonStyle = {
    background: 'none',
    border: 'none',
    padding: 0,
    marginLeft: '8px',
    cursor: 'pointer',
    color: '#4e3fec',
    display: 'inline-flex',
    alignItems: 'center',
  };

  const popupStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    zIndex: 1001,
    maxWidth: '80%',
    maxHeight: '80%',
    overflow: 'auto',
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const helpContentStyle = {
    marginBottom: '10px',
  };

  const helpContentTitleStyle = {
    fontWeight: 'bold',
    marginRight: '5px',
  };

  const helpContentExampleStyle = {
    fontWeight: 'normal',
  };

  const helpContent = [
    { title: "Simple equality", example: "state = 'OH', state != 'OH'"},
    { title: "Numeric comparison", example: "starting_price <= 50000" },
    { title: "Boolean check", example: "is_occupied = true, is_occupied = false, is_occupied, not is_occupied" },
    { title: "Date range", example: "year_built between 1990 and 2000, year_built > 1995"},
    { title: "Wild card matching", example: "assetname like '%Arcadia%'"},
    { title: "Multiple conditions", example: "(state = 'OH' or state='TX') and is_occupied = true and starting_price < 50000" },
  ];

  const opContent = [
    { op: "< ", desc: "Less than"},
    { op: "> ", desc: "Greater than"},
    { op: "= ", desc: "Equal to"},
    { op: "<= ", desc: "Less than or equal to"},
    { op: ">= ", desc: "Greater than or equal to"},
    { op: "not ", desc: "Negator in boolean expression"},
    { op: "and,or " , desc: "Condition joiners - use parentheses for more complex expressions"},
    { op: "between ", desc: "range used to evaluate an integer"},
    { op: "like ", desc: "Pattern matching - % is wildcard"},
    { op: "( - * / )" , desc: "Arithmtic operators"},
  ];

  return (
    <>
      <button 
        type="button"
        style={iconButtonStyle} 
        onClick={handleClick}
      >
        <HelpCircle size={16} />
      </button>
      {isOpen && (
        <>
          <div style={overlayStyle} onClick={() => setIsOpen(false)} />
          <div style={popupStyle}>
            <h2>Property Filter Help</h2>
            <p>The Property Filter allows you to refine your query results. Here are some examples:</p>
            {helpContent.map((item, index) => (
              <div key={index} style={helpContentStyle}>
                <span style={helpContentTitleStyle}>{index + 1}. {item.title}:</span>
                <span style={helpContentExampleStyle}>{item.example}</span>
              </div>
            ))}
            <h2>Operators</h2>
            {opContent.map((item, index) => (
              <div key={index} style={helpContentStyle}>
                <span style={helpContentTitleStyle}>{index + 1}. {item.op}</span>
                <span style={helpContentExampleStyle}>{item.desc}</span>
              </div>
            ))}
            <span style={helpContentExampleStyle}>
              <p>The filter supports simple arithemetic expressions. Remember to use single quotes for string values and no quotes for numeric or boolean values.</p>
            </span>
            <button onClick={() => setIsOpen(false)}>Close</button>
          </div>
        </>
      )}
    </>
  );
};

export default PropertyFilterHelpButton;
